import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { dynamicSort } from "pages/MeerContent";
import { collection, query, where, getDocs } from "firebase/firestore";
import CMSConfig from "../../CMSConfig.json";

const PostsContainer = styled.div`
  ${tw` absolute border-2  invisible xl:visible right-0 overflow-y-hidden shadow-md rounded-xl w-56`}
  z-index:40;
  max-height: 40vh;
  top: 30vh;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(14, 165, 233, 0.5);
`;

const PostHolder = styled.div`
  ${tw`overflow-y-scroll p-2`} max-height: 36vh;
`;

const TitleTop = styled.h2`
  ${tw`z-50 py-2 border-b-2 text-xl text-center mx-auto text-gray-800  font-bold mb-2`}
  border-color: rgba(255, 255, 255, 0.5);
`;
const Post = styled.a`
  ${tw` w-full bg-white z-50 cursor-pointer`}
`;

const Title = tw.h5`transition-all mb-4  font-bold leading-tight text-gray-700 duration-300 group-hover:text-sky-300`;

class PostDummy {
  data() {
    return {
      imageURL: process.env.PUBLIC_URL + "images/grey_bg.jpeg",
      imgFitContain: false,
      htmlfreetext: "",
      authorImgUrl: process.env.PUBLIC_URL + "images/grey_bg.jpeg",
      showAuthor: true,
      doPulse: true,
    };
  }
}

//-----------------------------------------------------------------------------------------------

//Component rendering two populars and six recent articles from storage with tabs
export default function NewsHighlight(props) {
  //To redirect to page later as prop?
  const history = useNavigate();
  const [highlightPosts, sethighlightPosts] = useState();
  const [hasReplacedPinnedDummys, setReplacedPinnedDummys] = useState(false);

  async function GetHighlights() {
    var all = [];
    // FIrst load general pinned posts for this category
    // var query = db.where("categoryMap.Nieuws", "==", true);
    const q = query(
      collection(props.firestore, "documents"),
      where("isHighlight", "==", true)
    );
    const result = await getDocs(q);
    if (result.docs) {
      all = all.concat(result.docs);
    }

    const newToOld = true;
    all.sort(
      dynamicSort(
        newToOld
          ? "-" + (CMSConfig.datePropertyName || "date")
          : CMSConfig.datePropertyName || "date"
      )
    );

    setReplacedPinnedDummys(true);

    console.log("highlight loaded", all);

    sethighlightPosts(all);

    props.scrollTo();
  }

  useEffect(() => {
    if (!hasReplacedPinnedDummys && props.maxBatchDoc) GetHighlights();
  }, [hasReplacedPinnedDummys, props.maxBatchDoc]);

  //Render results in tailwind elements
  return (
    <>
      {" "}
      {highlightPosts && (
        <PostsContainer>
          <TitleTop>Nieuws</TitleTop>
          <PostHolder>
            {highlightPosts.map((post, index) => (
              <Post
                key={index}
                className="group"
                onClick={() =>
                  history("/blogs/" + encodeURIComponent(post.data().slug))
                }
              >
                <Title>{post.data().title}</Title>
              </Post>
            ))}
          </PostHolder>
        </PostsContainer>
      )}
    </>
  );
}
