import React, { useEffect, useState } from "react";
import { StyledDiv } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";

import { navLinks } from "components/hero/CISNavLinks";

import { useLocation } from "react-router";

import { Link, useNavigate } from "react-router-dom";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { Labels } from "engine/Labels";
import { Helmet } from "react-helmet";
import { Toggle } from "engine/Toggle";

const Heading = tw.h1`text-gray-900 text-4xl sm:text-5xl  text-black tracking-wide text-center`;

const ArchiveContainer = tw.div`flex justify-start flex-col sm:flex-row`;
const SideBarSettings = tw.div`w-64 left-0 flex flex-col justify-start`;
const ItemsGrid = tw.div`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full mt-4 sm:ml-4 p-6 pr-0 border-2 rounded-xl`;

const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded-md leading-none mt-12`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition-all duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-sky-500! text-gray-100!`}
  }
`;

const Post = styled(Link)`p-6 cursor-pointer flex flex-col `;
const Image = styled.img`
  ${tw`h-40 object-contain w-56  rounded-md`} ${(props) =>
    props.imgFitContain ? tw`object-contain` : tw`object-cover`}
`;
const Title = tw.h5`mt-6 text-xl font-bold transition-all duration-300 group-hover:text-sky-300`;
const Description = tw.p` font-medium text-gray-100 leading-loose text-sm`;
const AuthorInfo = tw.div`mt-1 flex items-center mb-6`;
const AuthorImage = tw.img`w-6 h-6 rounded-full object-cover`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-sm`;
const AuthorProfile = tw.p`text-gray-100 text-sm`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800 leading-loose`}
  p {
    ${tw` leading-loose`}
  }
  br {
    ${tw`mt-2 leading-loose`}
  }
  span {
    ${tw`mt-0 leading-loose`}
  }
  a {
    ${tw`mt-2 leading-loose text-blue-500`}
  }
  h2 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h3 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h4 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const Bottom = tw.div`flex justify-between`;
const Pagination = tw.div`flex gap-2 mt-4`;
const CurrentPage = tw.button`h-12 w-12 border-2 p-2 rounded-md bg-sky-500 text-gray-100 hocus:bg-sky-700 font-bold hocus:text-gray-200  focus:outline-none transition-all duration-300`;
const DisabledPage = tw.button`h-12 w-12 border-2 p-2 rounded-md bg-gray-200 text-gray-100`;
const HiddenLabel = tw.label`bg-gray-500 text-gray-100 h-6 p-1 text-center rounded-full`;
function ConstructBaseQuery(
  query,
  dbRef,
  selectedLbls,
  selectedAuthor,
  selectedPublicist,
  selectedCats,
  user
) {
  for (var i = 0; i < selectedLbls.length; i++) {
    if (i === 0)
      query = dbRef.where("labelMap." + String(selectedLbls[i]), "==", true);
    else query = query.where("labelMap." + String(selectedLbls[i]), "==", true);
  }
  for (var i = 0; i < selectedCats.length; i++) {
    if (i === 0 && query === null)
      query = dbRef.where("categoryMap." + String(selectedCats[i]), "==", true);
    else
      query = query.where("categoryMap." + String(selectedCats[i]), "==", true);
  }
  if (selectedAuthor) {
    if (query === null) query = dbRef.where("authorName", "==", selectedAuthor);
    else query = query.where("authorName", "==", selectedAuthor);
  }
  if (selectedPublicist) {
    if (query === null)
      query = dbRef.where("publicistName", "==", selectedPublicist);
    else query = query.where("publicistName", "==", selectedPublicist);
  }
  //Default none query
  if (query === null)
    if (!user || (user && !user.rights.includes("Posts redacteren"))) {
      //If not a user with rights only show visible
      query = dbRef.where("visible", "==", true);
    }
    //Otherwise add visible to the query
    //Unless querying as a person with redacting rights
    else if (!user || (user && !user.rights.includes("Posts redacteren")))
      query = query.where("visible", "==", true);

  if (query === null) query = dbRef;
  return query;
}

export //For sorting the posts by date
function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    //If customdate is given sort by that
    //NOTE: This only sorts per batch, with customdate somethings might be in the wrong batch
    var aProperty = property;
    var bProperty = property;
    if (property.includes("creationDateAsNumber")) {
      if (a.data().useCostumDate) {
        aProperty = "customDateAsNumber";
      }
      if (b.data().useCostumDate) {
        bProperty = "customDateAsNumber";
      }
    }
    var result =
      a.data()[aProperty] < b.data()[bProperty]
        ? -1
        : a.data()[aProperty] > b.data()[bProperty]
        ? 1
        : 0;

    return result * sortOrder;
  };
}

export default (props) => {
  const history = useNavigate();

  const locationData = useLocation();

  const [readParams, setReadParams] = useState(false);
  //Filters
  const [selectedLbls, setSelectedLbls] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState();
  const [selectedPublicist, setSelectedPublicist] = useState();
  // We set this vlaue after loading the first posts and for creating multiple pages
  const [averageBatchesPerPage, setaverageBatchesPerPage] = useState();
  const [numPages, setNumPages] = useState();

  const [newToOld, setNewToOld] = useState(true);
  const tabsKeys = ["Oud - nieuw", "Nieuw - oud"];
  const [activeTab, setActiveTab] = useState(tabsKeys[1]);

  //set filters based on params
  useEffect(() => {
    if (!readParams) {
      setReadParams(true);
      locationData &&
        locationData.category &&
        (selectedCats.length === 0 || !selectedCats) &&
        setSelectedCats([locationData.category]);
      locationData &&
        locationData.author &&
        !selectedAuthor &&
        setSelectedAuthor(locationData.author);
      locationData &&
        locationData.publicist &&
        !selectedPublicist &&
        setSelectedPublicist(locationData.publicist);
    }
  }, []);
  //-----------------------------------------------

  //Database varaibles
  const db = props.firestore.collection("documents");
  const [maxBatchDoc, loading, error] = useDocumentData(
    props.firestore.collection("metadata").doc("batching")
  );
  var maxBatches = null;
  if (maxBatchDoc) maxBatches = Math.floor(maxBatchDoc.docCount / 16);
  //-----------------------------------------------

  //Paging variables
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState();
  const [postsLastPage, setPostsLastPage] = useState([]);
  const [oldOverflows, setOldOverflows] = useState([]);
  const [showAllPageIcons, setShowAllPageIcons] = useState(false);
  var postsProxy = [];
  const [lastBatchNr, setLastBatch] = useState();
  const [previousBatchNr, setPreviousBastNr] = useState([]);
  const [overflow, setOverflow] = useState();
  //-----------------------------------------------

  //Either load the first page
  async function GetBatchesSpecificPage(page) {
    console.log("starting specific page loading");
    setPage(page);
    //start at avgBatches * page
    var currentBatch = averageBatchesPerPage * page - averageBatchesPerPage;
    var endBatchPage = averageBatchesPerPage * page - 1;
    //or avgBatches * (numPages - page)
    if (newToOld) {
      currentBatch =
        (numPages - page) * averageBatchesPerPage + averageBatchesPerPage;
      endBatchPage = (numPages - page) * averageBatchesPerPage;
    }

    // console.log(
    //   `Loaded posts from batch: ${currentBatch} to batch: ${endBatchPage} for page ${page}`
    // );
    //If there are more available batches and posts is not filled yet
    while (
      (!newToOld && currentBatch <= endBatchPage) ||
      (newToOld && currentBatch >= endBatchPage)
    ) {
      //Get query based on search criteria
      var query = ConstructBaseQuery(
        null,
        db,
        selectedLbls,
        selectedAuthor,
        selectedPublicist,
        selectedCats,
        props.userData && props.userData.exists ? props.userData.data() : null
      );
      //Get current batch
      query = query.where("batch", "==", currentBatch);
      const result = await query.get();
      if (result.docs) {
        var docArray = result.docs;
        //Put all else in overflow (might be nothing) ->
        // Important log can be reactivated
        // console.log("batch", currentBatch, "length", docArray.length);
        //Put everything that fits in the posts proxy (so the while immedtialy recognizes thaat we are full) <-
        postsProxy.push(...docArray);
        if (!newToOld) currentBatch++;
        else currentBatch--;
        //And write the proxy to actual psots which ight take a few seconds

        //Set the average number of docs per page
      }
    }
    postsProxy.sort(
      dynamicSort(newToOld ? "-creationDateAsNumber" : "creationDateAsNumber")
    );
    setPosts(postsProxy);
  }

  //Either load the first page
  async function GetFirstPosts() {
    setPage(1);
    //start at 0 for first page
    var currentBatch = 0;
    //unless we load from new to old: then do it the other way around
    if (newToOld) currentBatch = maxBatches;

    //If there are more available batches and posts is not filled yet
    while (
      postsProxy.length < 16 &&
      ((!newToOld && currentBatch <= maxBatches) ||
        (newToOld && currentBatch >= 0))
    ) {
      //Get query based on search criteria
      var query = ConstructBaseQuery(
        null,
        db,
        selectedLbls,
        selectedAuthor,
        selectedPublicist,
        selectedCats,
        props.userData && props.userData.exists ? props.userData.data() : null
      );
      //Get current batch
      query = query.where("batch", "==", currentBatch);
      const result = await query.get();
      if (result.docs) {
        var docArray = result.docs;
        //Put all else in overflow (might be nothing) ->
        const fittingNr = 16 - postsProxy.length;
        //Log can be reactivated
        // console.log(
        //   "batch",
        //   currentBatch,
        //   "length",
        //   docArray.length,
        //   "fits",
        //   fittingNr,
        //   "setting overflow with"
        // );
        setOverflow(docArray.splice(fittingNr, docArray.length - fittingNr));
        //Put everything that fits in the posts proxy (so the while immedtialy recognizes thaat we are full) <-
        postsProxy.push(...docArray.splice(0, fittingNr));
        if (!newToOld) currentBatch++;
        else currentBatch--;
        //And write the proxy to actual psots which ight take a few seconds

        //Set the average number of docs per page
      }
    }
    postsProxy.sort(
      dynamicSort(newToOld ? "-creationDateAsNumber" : "creationDateAsNumber")
    );
    setPosts(postsProxy);
    const avgNumBatches = newToOld ? maxBatches - currentBatch : currentBatch;
    setaverageBatchesPerPage(avgNumBatches);
    setNumPages(Math.max(Math.floor(maxBatches / avgNumBatches) + 1, 2));

    console.log(
      "batches on this page",
      newToOld ? maxBatches - currentBatch : currentBatch,
      "pages",
      Math.ceil(maxBatches / avgNumBatches)
    );
    //overflow should be filled now
    //So ending batch can be set
    setLastBatch(currentBatch);
    console.log(
      `Loaded posts with final batch: ${currentBatch}, max batch: ${maxBatches}`
    );
  }

  async function GetPostsOfPreviousPage(page) {
    console.log(
      "settings posts of previous page",
      postsLastPage[page - 1].length,
      postsLastPage,
      "with overflow",
      oldOverflows[page - 1]
    );
    postsProxy = postsLastPage[page - 1];
    setOverflow(oldOverflows[page - 1]);
    setLastBatch(previousBatchNr[page - 1]);
    setPosts(postsProxy);
    setPage(page);
  }

  async function GetNextPagePosts() {
    console.log("starting with overflow", overflow);
    //Save the previous posts
    var temp = postsLastPage;
    temp.push(posts);
    setPostsLastPage(temp);

    //same for overflow
    temp = oldOverflows;
    temp.push(overflow);
    setOldOverflows(temp);

    //remember batch nr of previous page
    temp = previousBatchNr;
    temp.push(lastBatchNr);
    setPreviousBastNr(temp);
    console.log("setting", lastBatchNr);

    //dont forget to set next page
    setPage(page + 1);
    //clear proxy
    postsProxy = [];
    //this time start at the last batches ending
    var currentBatch = lastBatchNr;

    //First load overflow into posts proxy
    postsProxy.push(...overflow);

    //After that start appending the rest
    while (
      postsProxy.length < 16 &&
      ((!newToOld && currentBatch <= maxBatches) ||
        (newToOld && currentBatch >= 0))
    ) {
      //Get query based on search criteria
      var query = ConstructBaseQuery(
        null,
        db,
        selectedLbls,
        selectedAuthor,
        selectedPublicist,
        selectedCats,
        props.userData && props.userData.exists ? props.userData.data() : null
      );
      //Get current batch
      query = query.where("batch", "==", currentBatch);

      const result = await query.get();
      if (result.docs) {
        var docArray = result.docs.sort(
          dynamicSort(
            newToOld ? "-creationDateAsNumber" : "creationDateAsNumber"
          )
        );

        //Put all else in overflow (might be nothing) ->
        const fittingNr = 16 - postsProxy.length;
        setOverflow(docArray.splice(fittingNr, docArray.length - fittingNr));
        //Put everything that fits in the posts proxy (so the while immedtialy recognizes thaat we are full) <-
        postsProxy.push(...docArray.splice(0, fittingNr));
        //And write the proxy to actual psots which ight take a few seconds
        if (!newToOld) currentBatch++;
        else currentBatch--;
      }
    }
    setPosts(postsProxy);

    //overflow should be filled now
    //So ending batch can be set
    setLastBatch(currentBatch);
    //Fairly important log, can be reactivated
    // console.log(
    //   `Loaded posts with final batch: ${
    //     currentBatch - 1
    //   }, max batch: ${maxBatches}, used overflow: ${overflow.length}`
    // );
  }

  //Or load the next page by overflow and current batch

  //Or the previous page by previous overflow and batch

  useEffect(() => {
    if (activeTab === "Oud - nieuw" && newToOld) {
      setNewToOld(false);
    } else if (activeTab === "Nieuw - oud" && !newToOld) {
      setNewToOld(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (maxBatches) GetFirstPosts();
  }, [
    selectedAuthor,
    selectedPublicist,
    selectedCats,
    selectedLbls,
    maxBatches,
    newToOld,
  ]);

  // Translate the number of pages to page icons at bottom
  var pageList = [];
  for (let i = 1; i < numPages + 1; i++) pageList.push(i);

  return (
    <StyledDiv>
      <Helmet>
        <title>Meer Artikelen</title>
        <meta
          name="description"
          content="Welkom bij het centrum voor Israëlstudies."
        />
      </Helmet>
      <Heading>Meer</Heading>

      <ArchiveContainer>
        <SideBarSettings>
          <Text>
            <h2>Opties</h2>
            {/* idem below <h4>Categoriën</h4> */}
          </Text>
          {/* could activate but categories are selected at the top /<MultiDropdown></MultiDropdown> */}
          <Text>
            <h4>Categorieën</h4>
          </Text>
          <Labels
            metadataDoc={maxBatchDoc}
            onChange={(values) => {
              const result = [];
              values.forEach((val) => result.push(val.label));
              setSelectedCats(result);
            }}
            firestore={props.firestore}
            firebase={props.firebase}
            defaultLabels={
              locationData
                ? locationData.category
                  ? [locationData.category]
                  : null
                : null
            }
            itemType="categorien"
            noCreate={true}
            noMinWidth={true}
          ></Labels>
          <Text>
            <h4>Labels</h4>
          </Text>
          <Labels
            metadataDoc={maxBatchDoc}
            onChange={(values) => {
              const result = [];
              values.forEach((val) => result.push(val.label));
              setSelectedLbls(result);
            }}
            firestore={props.firestore}
            firebase={props.firebase}
            defaultLabels={
              locationData
                ? locationData.labels
                  ? [locationData.labels]
                  : null
                : null
            }
            itemType="labels"
            noCreate={true}
            noMinWidth={true}
          ></Labels>
          <Text>
            <h4>Auteur</h4>
          </Text>
          <Labels
            metadataDoc={maxBatchDoc}
            onChange={(values) => {
              setSelectedAuthor(values.length > 0 ? values[0].label : null);
            }}
            firestore={props.firestore}
            firebase={props.firebase}
            defaultLabels={
              locationData
                ? locationData.author
                  ? [locationData.author]
                  : null
                : null
            }
            itemType="authors"
            noCreate={true}
            useObjNames={true}
            noMinWidth={true}
          ></Labels>
          <Text>
            <h4>Publicist</h4>
          </Text>
          <Labels
            metadataDoc={maxBatchDoc}
            onChange={(values) =>
              setSelectedPublicist(values.length > 0 ? values[0].label : null)
            }
            firestore={props.firestore}
            firebase={props.firebase}
            defaultLabels={
              locationData
                ? locationData.publicist
                  ? [locationData.publicist]
                  : null
                : null
            }
            itemType="publicists"
            noCreate={true}
            noMinWidth={true}
          ></Labels>{" "}
          <Text>
            <h4>Datum sortering</h4>
          </Text>
          <TabsControl>
            {tabsKeys.map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
          <Text>
            <h4>Paginanummering</h4>
          </Text>
          <Toggle
            disabled={false}
            text="Toon alle paginanummers:"
            parentMethod={async () => {
              setShowAllPageIcons(!showAllPageIcons);
            }}
            active={showAllPageIcons}
          />
        </SideBarSettings>
        <ItemsGrid>
          {posts &&
            posts.map((post, index) => (
              <Post
                key={index}
                href={post.data().url}
                className="group"
                to={"/blogs/" + post.id}
              >
                <Image
                  src={post.data().imageURL}
                  imgFitContain={post.data().imgFitContain}
                  alt={post.data().title + " main image"}
                />
                <span>
                  <Title>{post.data().title}</Title>
                  {!post.data().visible && <HiddenLabel>Verborgen</HiddenLabel>}
                </span>
                <Description>
                  {post.data().useCustomSummary
                    ? post.data().customSummary.substring(0, 126)
                    : post.data().htmlfreetext &&
                      post
                        .data()
                        .htmlfreetext.substring(0, 126)
                        .replaceAll(".", ". ")
                        .replaceAll("\n", " ") + "..."}
                </Description>
                {/* {console.log("pub date", post.data().publicationDateInSeconds)} */}
                {post.data().showAuthor && (
                  <AuthorInfo>
                    {<AuthorImage src={post.data().authorImgUrl} />}
                    <AuthorNameAndProfession>
                      <AuthorName>{post.data().authorName}</AuthorName>
                      <AuthorProfile>{post.data().authorProfile}</AuthorProfile>
                    </AuthorNameAndProfession>
                  </AuthorInfo>
                )}
              </Post>
            ))}
        </ItemsGrid>
      </ArchiveContainer>
      <Bottom>
        <span></span>
        {!showAllPageIcons ? (
          <Pagination>
            {page - 1 > 0 && (
              <CurrentPage
                onClick={() => {
                  page - 1 === 1
                    ? GetPostsOfPreviousPage(page - 1)
                    : GetPostsOfPreviousPage(page - 1);
                }}
              >
                {page - 1}
              </CurrentPage>
            )}
            {posts && <DisabledPage disabled>{page}</DisabledPage>}
            {/* {currentPage + 1 < totalPages && ( */}
            {posts && posts.length === 16 && overflow && (
              <CurrentPage
                onClick={() => {
                  GetNextPagePosts();
                }}
              >
                {page + 1}
              </CurrentPage>
            )}
            {/* )} */}
          </Pagination>
        ) : (
          // Else we show all the pages
          <Pagination>
            {pageList.map((item, index) => (
              <CurrentPage
                key={index}
                onClick={() => GetBatchesSpecificPage(index + 1)}
              >
                {index + 1}
              </CurrentPage>
            ))}
          </Pagination>
        )}
      </Bottom>
    </StyledDiv>
  );
};
